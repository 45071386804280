<template>
	<div style="background: #f5f5fa; padding: 2rem 0; box-sizing: border-box">
		<div class="ct_box">
			<div class="ct_title">
				<h3 class="title">{{ $store.state.form.title }}</h3>
				<h3 class="shortTitle">{{ $store.state.form.shortTitle }}</h3>
				<p class="time">{{ $store.state.form.time }}</p>
			</div>
			<div v-html="$route.query.content">
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	mounted() {
		console.log(this.$store.state.form);
	},
	computed: {
		...mapState(['form']),
	},
	data() {
		return {
			title: '',
		}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.ct_box {
	width: 80%;
	min-height: 94vh;
	background: #fff;
	border-radius: 0.83rem;
	margin: 0 auto;
	padding: 2rem 0.5rem;
	box-sizing: border-box;
	.ct_title{
		padding-bottom: 2rem;
		box-sizing: border-box;
		border-bottom: 0.08rem solid #A7A7A7;
		text-align: center;
		.title{
			font-size: 2rem;
			color: #333;
		}
		.shortTitle{
			color: #333;
			font-size: 1.47rem;
			margin: 1.5rem 0 1rem 0;
		}
		.time{
			color:#A7A7A7 ;
			font-size: 1.2rem;
		}
	}
}
</style>